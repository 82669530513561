import {Feature} from "ol";
import {Circle} from "ol/geom";
import {fromLonLat} from "ol/proj";
import windDirectionToString from "../../../../windUtil";
import {Style} from "ol/style";
import {log_interpolate_range, range} from "../../../../interpolate";
import config from "../../../../app/config";
import TriangleFeature from "./TriangleFeature";
import Vector2D from "../../../../Vector2D";
import {scaledPixel} from "../../../../util";
import {MetSite} from "../../types";


const MetStationFeature = function (s: MetSite, isSelected: boolean, zoom: number, windSpeed?: number, windDirection?: number): Feature<any> {
    const radius = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, 240, .1, zoom)
    let font_size_small = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, scaledPixel(9), scaledPixel(30), zoom);
    let font_size_large = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, scaledPixel(12), scaledPixel(60), zoom);
    // it's not obvious to me why the triangle radius is measured in pixels while the outer radius doesn't seem to be
    let triangle_radius = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, scaledPixel(10), scaledPixel(30), zoom);
    const coordinate = s.coordinate

    const feature = new Feature({
        geometry: new Circle(fromLonLat(coordinate), radius)
    })
    const windDataPoint = {
        windSpeed: windSpeed,
        windDirection: windDirection
    }

    feature.setStyle(
        new Style({
            renderer: function renderer(coordinates, state) {

                let coordinates_0 = coordinates[0] as number[];
                let x = coordinates_0[0];
                let y = coordinates_0[1];
                let coordinates_1 = coordinates[1] as number[];
                let x1 = coordinates_1[0];
                let y1 = coordinates_1[1];
                let ctx = state.context;
                let dx = x1 - x;
                let dy = y1 - y;
                let radius = Math.sqrt(dx * dx + dy * dy);

                let bgcolor;
                if (isSelected) {
                    bgcolor = 'rgba(0,0,255,.7)';
                } else {
                    bgcolor = 'rgba(128,128,128,.7)';
                }

                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI, true);

                ctx.fillStyle = bgcolor;
                ctx.fill();
                // ctx.strokeStyle = 'rgba(255,255,255,1)'
                // ctx.lineWidth = 3
                // ctx.stroke();



                if (typeof windDataPoint !== 'undefined' && typeof windDataPoint.windSpeed !== 'undefined') {
                    ctx.fillStyle = 'white'
                    ctx.textAlign = "center";

                    ctx.font = 'bold ' + font_size_large + 'px sans-serif';
                    if (typeof windDataPoint.windDirection !== 'undefined') {
                        ctx.fillText(windDirectionToString(windDataPoint.windDirection), x, y + font_size_large / 2)
                    }

                    ctx.font = font_size_small + 'px sans-serif';
                    ctx.fillText('wind from', x, y - radius + (font_size_small * 2));

                    const mph = windDataPoint.windSpeed;
                    ctx.font = font_size_small + 'px sans-serif';
                    ctx.fillText(mph.toFixed(1) + ' mph', x, y + radius - font_size_small);


                    ctx.fillStyle = bgcolor;
                    if (typeof windDirection !== 'undefined') {
                        let triangle_center = new Vector2D(0, 1)
                            .rotated(windDirection)
                            .scaled(radius)
                            .sum(new Vector2D(x, y))

                        TriangleFeature(
                            ctx,
                            triangle_radius,
                            triangle_center.sum(
                                new Vector2D(0, 1).rotated(windDirection).scaled(triangle_radius * 1.3)
                            ),
                            new Vector2D(0, 1).rotated(windDirection),
                            bgcolor
                        )
                    }
                }

                // label
                let label_center = new Vector2D(0,1)
                    .scaled(-1 * radius * 1.3)

                if(windDirection !== undefined) {
                    label_center = label_center.rotated(windDirection)
                }

                label_center = label_center.sum(new Vector2D(x,y));


                ctx.beginPath()
                ctx.fillStyle = bgcolor
                ctx.arc(label_center.x, label_center.y, triangle_radius, 0, 2 * Math.PI, true)
                ctx.fill()

                ctx.fillStyle = 'white'
                ctx.font = 'bold ' + font_size_small + 'px sans-serif';

                ctx.fillText(s.letter || "", label_center.x, label_center.y + font_size_small/2);

            }
        })
    )

    return feature;
}

export default MetStationFeature;